<template>
  <b-overlay :show="is_busy" rounded="sm">
    <b-container fluid>
      <div class="text-center mb-2">
        <h2><strong>Result Sheet</strong></h2>
        <h5>
          {{ term }}<br>
          {{ session.name }} Academic Session<br>
          {{ room.name }}{{ room.form }}<br>
          {{ subject.name }}<br>
          <b-button variant="primary" size="sm" @click="editResult" v-if="is_edit==false">Edit Result</b-button>
          <b-button variant="primary" size="sm" @click="editResult" v-else>Save Result</b-button>
        </h5>
      </div>

      <div class="card">
        <div class="card-body" v-if="is_edit==false">
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr class="text-center">
                  <th><a @click="sortList('student')" href="javascript:void(0);">Name &#8597;</a></th>
                  <th>1st Assmt <br> {{ standard.test1 }}%</th>
                  <th v-if="standard.assessment>1">2nd Assmt <br> {{ standard.test2 }}%</th>
                  <th v-if="standard.assessment>2">3rd Assmt <br> {{ standard.test3 }}%</th>
                  <th v-if="standard.assessment>3">4th Assmt <br> {{ standard.test4 }}%</th>
                  <th>Exam <br> {{ standard.exam }}%</th>
                  <th>Total <br> 100%</th>
                  <th>Grade <br> Point</th>
                  <th>Teacher's <br>Comment</th>
                  <!-- <th>Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="result in req.results" :key="result.id">
                  <td v-if="result.student">{{ result.student }}</td>
                  <td>{{ result.first_test }}</td>
                  <td v-if="standard.assessment>1">{{ result.second_test }}</td>
                  <td v-if="standard.assessment>2">{{ result.third_test }}</td>
                  <td v-if="standard.assessment>3">{{ result.forth_test }}</td>
                  <td>{{ result.exam }}</td>
                  <td>{{ result.total }}</td>
                  <td>{{ result.grade }}</td>
                  <td>{{ result.comment }} </td>
                  <!-- <td>
                    <b-button variant="primary" size="sm" @click="editModal(result)">
                      Edit Result
                    </b-button>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-body" v-else>
          <div v-if="errors.length>0" class="p-2 mb-2 alert alert-danger">
            <li v-for="error in errors"  :key="error.student">
              <span class="text-info">{{ error }}</span>'s result should not be more than 100%
            </li>
          </div>
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr class="text-center">
                  <th width="200">Name</th>
                  <th>1st Assmt <br> {{ standard.test1 }}%</th>
                  <th v-if="standard.assessment>1">2nd Assmt <br> {{ standard.test2 }}%</th>
                  <th v-if="standard.assessment>2">3rd Assmt <br> {{ standard.test3 }}%</th>
                  <th v-if="standard.assessment>3">4th Assmt <br> {{ standard.test4 }}%</th>
                  <th>Exam <br> {{ standard.exam }}%</th>
                  <th>Total <br> 100%</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="result in req.results" :key="result.id">
                  <td v-if="result.student">{{ result.student }}</td>
                  <td> <b-form-input type="number" v-model="result.first_test" step="0.1" @input="update1result(result)" :max="standard.test1" size="sm"></b-form-input></td>
                  <td v-if="standard.assessment>1"><b-form-input type="number" v-model="result.second_test" step="0.1" @input="update2result(result)" :max="standard.test2" size="sm"></b-form-input></td>
                  <td v-if="standard.assessment>2"><b-form-input type="number" v-model="result.third_test" step="0.1" @input="update3result(result)" :max="standard.test3" size="sm"></b-form-input></td>
                  <td v-if="standard.assessment>3"><b-form-input type="number" v-model="result.forth_test" step="0.1" @input="update4result(result)" :max="standard.test4" size="sm"></b-form-input></td>
                  <td><b-form-input type="number" v-model="result.exam" step="0.1" @input="updateresult(result)" size="sm"></b-form-input></td>
                  <td><b-form-input type="number" v-model="result.total" step="0.1" readonly></b-form-input></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <b-button variant="primary" class="float-right" size="sm" @click="editResult" v-if="is_edit==true">Save Result</b-button>
        </div>
      </div>

      <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit Result</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="updateUser()">
                  <div class="modal-body row">
                    <div class="form-group col-md-4">
                      <label>1st Assessment ({{ standard.test1 }}%)</label>
                      <input v-model="form.first_test" type="number" step="0.1" class="form-control" @input="update1assmt()" :max="standard.test1">
                    </div>

                    <div class="form-group col-md-4" v-if="standard.assessment>1">
                      <label>2nd Assessment ({{ standard.test2 }}%)</label>
                      <input v-model="form.second_test" type="number" step="0.1" class="form-control" @input="update2assmt()" :max="standard.test2">
                    </div>

                    <div class="form-group col-md-4" v-if="standard.assessment>2">
                      <label>3rd Assessment ({{ standard.test3 }}%)</label>
                      <input v-model="form.third_test" type="number" step="0.1" class="form-control" @input="update3assmt()" :max="standard.test3">
                    </div>

                    <div class="form-group col-md-4" v-if="standard.assessment>3">
                      <label>4th Assessment ({{ standard.test4 }}%)</label>
                      <input v-model="form.forth_test" type="number" step="0.1" class="form-control" @input="update4assmt()" :max="standard.test4">
                    </div>

                    <div class="form-group col-md-4">
                      <label>Exam ({{ standard.exam }}%)</label>
                      <input v-model="form.exam" type="number" step="0.1" class="form-control" @input="updateExam()">
                    </div>

                    <div class="form-group col-md-4">
                      <label>Total (100%)</label>
                      <input v-model="form.total" type="number" step="0.1" readonly="true" class="form-control">
                    </div>

                    <div class="form-group col-md-4">
                      <label>Grade</label>
                      <input v-model="form.grade" type="text" readonly="true" class="form-control">
                    </div>

                    <!--<div class="form-group col-md-12">
                      <label>Comment</label>
                      <textarea v-model="form.comment" class="form-control"></textarea>
                    </div>-->
                      
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-success">Update</button>
                  </div>
                </form>
            </div>
          </div>
      </div>

    </b-container>
  </b-overlay>
</template>

<script>
  import axios from 'axios';
  import { Form } from 'vform';

  export default {
    created(){
      this.roleHelper(['1', '2', '5']);
      this.loadData();
    },
    
    data(){
      return{
        gender: [
          { value: null, text: 'Select Gender' },
          { value: 'male', text: 'Male' },
          { value: 'female', text: 'Female' },
        ],

        editmode: false,
        condiParam: {
          subject_id: '',
          class_id: '',
          session_id: '',
          term_id: '',
        },
        errors: [],
        req:{
          results: {},
        },
        
        standard: '',
        term: '',
        session: '',
        subject: '',
        room: '',
        form: new Form({
          id: '',
          first_test: '',
          second_test: '',
          third_test: '',
          forth_test: '',
          exam: '',
          total: '',
          grade: '',
          comment: '',
        }),
        is_edit: false,
        is_busy: false,
        sortedbyASC: true,
      }
    },

    methods: {
      sortList(sortBy) {
        if (this.sortedbyASC) {
          this.req.results.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
          this.sortedbyASC = false;
        } 
        else {
          this.req.results.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
          this.sortedbyASC = true;
        }
      },

      loadData(){
        this.condiParam.subject_id = this.$route.params.subject_id;
        this.condiParam.class_id = this.$route.params.class_id;
        this.condiParam.session_id = this.$route.params.session_id;
        this.condiParam.term_id = this.$route.params.term_id;

        if(!this.condiParam.subject_id || !this.condiParam.class_id || !this.condiParam.session_id  || !this.condiParam.term_id)
        {
            Swal.fire(
                "Failed!",
                "Ops, One of the needed parameters are not set, try again.",
                "warning"
            );
            this.$router.push({path: '/result/add'});
        }

        if (this.is_busy) return;
        this.is_busy = true;

        axios.get("/result", { params: this.condiParam }) 
        .then(({data}) => {
            
            this.req.results = data.data.results;
            this.standard = data.data.standard;
            this.session = data.data.session;
            this.room = data.data.room;
            this.term = data.data.term;
            this.subject = data.data.subject;
        })
    
        .catch((err)=>{
            Swal.fire(
                "Error!",
                err.response.data.data.error,
                "error"
            );
        })

        .finally(() => {
            this.is_busy = false;
        });
      },
      
      editResult(){
        if(this.is_edit==true){
          var err = [];
        
          this.req.results.forEach(function (result) {
            if(result.total > 100){
              err.push(result.student);
            }
          });

          this.errors = err;
          if(this.errors.length>0){

          }
          else {
            this.is_edit = false;
            axios.post('/result/update', this.req)
            .then(()=>{
              Swal.fire(
                'Updated!',
                'Results Updated Successfully.',
                'success'
              )
              this.loadData()
            })
            .catch((err)=>{
              console.log(err)
              // Swal.fire(
              //   "Error!",
              //   err.response.data.data.error,
              //   "error"
              // );
            });
            this.loadData()
          }
        }
        else {
          this.is_edit = true;
        }
      },

      editModal(room){
        this.editmode = true;
        this.form.reset();
        $('#addNew').modal('show');
        this.form.fill(room);
        console.log(room)
      },

      updateExam(){
        var A = '';
        if(this.standard.assessment==1){
          A = Number(this.form.first_test);
        }

        if(this.standard.assessment==2){
          A = Number(this.form.first_test) + Number(this.form.second_test);
        }

        if(this.standard.assessment==3){
          A = Number(this.form.first_test) + Number(this.form.second_test) + Number(this.form.third_test);
        }

        if(this.standard.assessment==4){
          A = Number(this.form.first_test) + Number(this.form.second_test) + Number(this.form.third_test) + Number(this.form.forth_test);
        }

        this.form.total = Number(this.form.exam) + A;


        if(this.form.total >= this.standard.a_min){
          this.form.grade = 'A'
        }

        else if(this.form.total >= this.standard.b_min){
          this.form.grade = 'B'
        }

        else if(this.form.total >= this.standard.c_min){
          this.form.grade = 'C'
        }


        else if(this.form.total >= this.standard.d_min){
          this.form.grade = 'D'
        }

        else if(this.form.total >= this.standard.e_min){
          this.form.grade = 'E'
        }

        else{
          this.form.grade = 'F'
        }
      },

      update1assmt(){
        var A = '';
        if(this.standard.assessment==1){
          A = Number(this.form.first_test);
        }

        if(this.standard.assessment==2){
          A = Number(this.form.first_test) + Number(this.form.second_test);
        }

        if(this.standard.assessment==3){
          A = Number(this.form.first_test) + Number(this.form.second_test) + Number(this.form.third_test);
        }

        if(this.standard.assessment==4){
          A = Number(this.form.first_test) + Number(this.form.second_test) + Number(this.form.third_test) + Number(this.form.forth_test);
        }

        this.form.total = Number(this.form.exam) + A;


        if(this.form.total >= this.standard.a_min){
          this.form.grade = 'A'
        }

        else if(this.form.total >= this.standard.b_min){
          this.form.grade = 'B'
        }

        else if(this.form.total >= this.standard.c_min){
          this.form.grade = 'C'
        }


        else if(this.form.total >= this.standard.d_min){
          this.form.grade = 'D'
        }

        else if(this.form.total >= this.standard.e_min){
          this.form.grade = 'E'
        }

        else{
          this.form.grade = 'F'
        }
      },

      update2assmt(){
        var A = '';
        if(this.standard.assessment==1){
          A = Number(this.form.first_test);
        }

        if(this.standard.assessment==2){
          A = Number(this.form.first_test) + Number(this.form.second_test);
        }

        if(this.standard.assessment==3){
          A = Number(this.form.first_test) + Number(this.form.second_test) + Number(this.form.third_test);
        }

        if(this.standard.assessment==4){
          A = Number(this.form.first_test) + Number(this.form.second_test) + Number(this.form.third_test) + Number(this.form.forth_test);
        }

        this.form.total = Number(this.form.exam) + A;


        if(this.form.total >= this.standard.a_min){
          this.form.grade = 'A'
        }

        else if(this.form.total >= this.standard.b_min){
          this.form.grade = 'B'
        }

        else if(this.form.total >= this.standard.c_min){
          this.form.grade = 'C'
        }


        else if(this.form.total >= this.standard.d_min){
          this.form.grade = 'D'
        }

        else if(this.form.total >= this.standard.e_min){
          this.form.grade = 'E'
        }

        else{
          this.form.grade = 'F'
        }
      },

      update3assmt(){
        var A = '';
        if(this.standard.assessment==1){
          A = Number(this.form.first_test);
        }

        if(this.standard.assessment==2){
          A = Number(this.form.first_test) + Number(this.form.second_test);
        }

        if(this.standard.assessment==3){
          A = Number(this.form.first_test) + Number(this.form.second_test) + Number(this.form.third_test);
        }

        if(this.standard.assessment==4){
          A = Number(this.form.first_test) + Number(this.form.second_test) + Number(this.form.third_test) + Number(this.form.forth_test);
        }

        this.form.total = Number(this.form.exam) + A;


        if(this.form.total >= this.standard.a_min){
          this.form.grade = 'A'
        }

        else if(this.form.total >= this.standard.b_min){
          this.form.grade = 'B'
        }

        else if(this.form.total >= this.standard.c_min){
          this.form.grade = 'C'
        }


        else if(this.form.total >= this.standard.d_min){
          this.form.grade = 'D'
        }

        else if(this.form.total >= this.standard.e_min){
          this.form.grade = 'E'
        }

        else{
          this.form.grade = 'F'
        }
      },

      update4assmt(){
        var A = '';
        if(this.standard.assessment==1){
          A = Number(this.form.first_test);
        }

        if(this.standard.assessment==2){
          A = Number(this.form.first_test) + Number(this.form.second_test);
        }

        if(this.standard.assessment==3){
          A = Number(this.form.first_test) + Number(this.form.second_test) + Number(this.form.third_test);
        }

        if(this.standard.assessment==4){
          A = Number(this.form.first_test) + Number(this.form.second_test) + Number(this.form.third_test) + Number(this.form.forth_test);
        }

        this.form.total = Number(this.form.exam) + A;

        if(this.form.total >= this.standard.a_min){
          this.form.grade = 'A'
        }

        else if(this.form.total >= this.standard.b_min){
          this.form.grade = 'B'
        }

        else if(this.form.total >= this.standard.c_min){
          this.form.grade = 'C'
        }


        else if(this.form.total >= this.standard.d_min){
          this.form.grade = 'D'
        }

        else if(this.form.total >= this.standard.e_min){
          this.form.grade = 'E'
        }

        else{
          this.form.grade = 'F'
        }
      },

      updateresult(result){
        var A = '';
        if(this.standard.assessment==1){
          A = Number(result.first_test);
        }

        if(this.standard.assessment==2){
          A = Number(result.first_test) + Number(result.second_test);
        }

        if(this.standard.assessment==3){
          A = Number(result.first_test) + Number(result.second_test) + Number(result.third_test);
        }

        if(this.standard.assessment==4){
          A = Number(result.first_test) + Number(result.second_test) + Number(result.third_test) + Number(result.forth_test);
        }

        result.total = Number(result.exam) + A;
        if(result.total > 100) {
          Swal.fire(
              "Mathematical Error",
              'The Total cannot be greater than 100, please calculate again',
              "error"
          );
          // result.first_test = '';
          // result.second_test = '';
          // result.third_test = '';
          // result.forth_test = '';
          // result.exam = '';
          //result.total = '';
        }
        

        if(result.total >= this.standard.a_min){
          result.grade = 'A'
        }

        else if(result.total >= this.standard.b_min){
          result.grade = 'B'
        }

        else if(result.total >= this.standard.c_min){
          result.grade = 'C'
        }


        else if(result.total >= this.standard.d_min){
          result.grade = 'D'
        }

        else if(result.total >= this.standard.e_min){
          result.grade = 'E'
        }

        else{
          result.grade = 'F'
        }
      },
      
      update1result(result){
        var A = '';

        if(Number(this.standard.test1) < Number(result.first_test)){
          Swal.fire(
              "System Error",
              'The 1st assessment cannot be greater than ' + this.standard.test1 + '%',
              "error"
          );
        }

        if(this.standard.assessment==1){
          A = Number(result.first_test);
        }

        if(this.standard.assessment==2){
          A = Number(result.first_test) + Number(result.second_test);
        }

        if(this.standard.assessment==3){
          A = Number(result.first_test) + Number(result.second_test) + Number(result.third_test);
        }

        if(this.standard.assessment==4){
          A = Number(result.first_test) + Number(result.second_test) + Number(result.third_test) + Number(result.forth_test);
        }

        result.total = Number(result.exam) + A;
        if(result.total > 100) {
          Swal.fire(
              "Mathematical Error",
              'The Total cannot be greater than 100, please calculate again',
              "error"
          );
        }

        if(result.total >= this.standard.a_min){
          result.grade = 'A'
        }

        else if(result.total >= this.standard.b_min){
          result.grade = 'B'
        }

        else if(result.total >= this.standard.c_min){
          result.grade = 'C'
        }


        else if(result.total >= this.standard.d_min){
          result.grade = 'D'
        }

        else if(result.total >= this.standard.e_min){
          result.grade = 'E'
        }

        else{
          result.grade = 'F'
        }
      },

      update2result(result){
        var A = '';
        if(this.standard.test2 < Number(result.second_test)){
          Swal.fire(
              "System Error",
              'The 2nd assessment cannot be greater than ' + this.standard.test2 + '%',
              "error"
          );
        }
        if(this.standard.assessment==1){
          A = Number(result.first_test);
        }

        if(this.standard.assessment==2){
          A = Number(result.first_test) + Number(result.second_test);
        }

        if(this.standard.assessment==3){
          A = Number(result.first_test) + Number(result.second_test) + Number(result.third_test);
        }

        if(this.standard.assessment==4){
          A = Number(result.first_test) + Number(result.second_test) + Number(result.third_test) + Number(result.forth_test);
        }

        result.total = Number(result.exam) + A;
        if(result.total > 100) {
          Swal.fire(
              "Mathematical Error",
              'The Total cannot be greater than 100, please calculate again',
              "error"
          );
        }


        if(result.total >= this.standard.a_min){
          result.grade = 'A'
        }

        else if(result.total >= this.standard.b_min){
          result.grade = 'B'
        }

        else if(result.total >= this.standard.c_min){
          result.grade = 'C'
        }


        else if(result.total >= this.standard.d_min){
          result.grade = 'D'
        }

        else if(result.total >= this.standard.e_min){
          result.grade = 'E'
        }

        else{
          result.grade = 'F'
        }
      },

      update3result(result){
        var A = '';

        if(this.standard.test3 < Number(result.third_test)){
          Swal.fire(
              "System Error",
              'The 3rd assessment cannot be greater than ' + this.standard.test3 + '%',
              "error"
          );
        }
        if(this.standard.assessment==1){
          A = Number(result.first_test);
        }

        if(this.standard.assessment==2){
          A = Number(result.first_test) + Number(result.second_test);
        }

        if(this.standard.assessment==3){
          A = Number(result.first_test) + Number(result.second_test) + Number(result.third_test);
        }

        if(this.standard.assessment==4){
          A = Number(result.first_test) + Number(result.second_test) + Number(result.third_test) + Number(result.forth_test);
        }

        result.total = Number(result.exam) + A;
        if(result.total > 100) {
          Swal.fire(
              "Mathematical Error",
              'The Total cannot be greater than 100, please calculate again',
              "error"
          );
        }

        if(result.total >= this.standard.a_min){
          result.grade = 'A'
        }

        else if(result.total >= this.standard.b_min){
          result.grade = 'B'
        }

        else if(result.total >= this.standard.c_min){
          result.grade = 'C'
        }


        else if(result.total >= this.standard.d_min){
          result.grade = 'D'
        }

        else if(result.total >= this.standard.e_min){
          result.grade = 'E'
        }

        else{
          result.grade = 'F'
        }
      },

      update4result(result){
        var A = '';
        if(this.standard.test4 < Number(result.forth_test)){
          Swal.fire(
              "System Error",
              'The 4th assessment cannot be greater than ' + this.standard.test4 + '%',
              "error"
          );
        }
        if(this.standard.assessment==1){
          A = Number(result.first_test);
        }

        if(this.standard.assessment==2){
          A = Number(result.first_test) + Number(result.second_test);
        }

        if(this.standard.assessment==3){
          A = Number(result.first_test) + Number(result.second_test) + Number(result.third_test);
        }

        if(this.standard.assessment==4){
          A = Number(result.first_test) + Number(result.second_test) + Number(result.third_test) + Number(result.forth_test);
        }

        result.total = Number(result.exam) + A;
        if(result.total > 100) {
          Swal.fire(
              "Mathematical Error",
              'The Total cannot be greater than 100, please calculate again',
              "error"
          );
        }
        if(result.total >= this.standard.a_min){
          result.grade = 'A'
        }

        else if(result.total >= this.standard.b_min){
          result.grade = 'B'
        }

        else if(result.total >= this.standard.c_min){
          result.grade = 'C'
        }


        else if(result.total >= this.standard.d_min){
          result.grade = 'D'
        }

        else if(result.total >= this.standard.e_min){
          result.grade = 'E'
        }

        else{
          result.grade = 'F'
        }
      },


      updateUser(){
        $('#addNew').modal('hide');
        if(this.is_busy) return;
        this.is_busy = true;
        axios.put('/api/result/'+this.form.id, this.form)
        .then(()=>{
          this.form.reset();
          
          Swal.fire(
              'Updated!',
              'Updated Successfully.',
              'success'
          )
          this.loadData();
        })

        .catch(() => {
          this.$Progress.fail();
        })
        .finally(() => {
            this.is_busy = false;
            this.loadData();
        }); 
      },

    },
  }
</script>

<style>
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: white;
    background-color: red;
    border-color: #dee2e6 #dee2e6 #f8fafc;
  }
</style>